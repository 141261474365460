<template>
    <div class="is-flex cart-timer">
        <div class="link">
          <i class="icon icon-android-alarm-clock"></i>
            <span>
              {{ expiresInText }}
            </span>
        </div>
    </div>
</template>
<script>
const leftPad = function(value) {
    if (value == 0) {
        return '00'
    }
    if (value < 10) {
        return '0' + value
    }
    return value
}

const formatTime = function(totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = Math.floor(totalSeconds - minutes * 60)
    return leftPad(minutes) + ':' + leftPad(seconds)
}

const secondsBetweenDates = (firstDate, secondDate) =>
    Math.abs((firstDate.getTime() - secondDate.getTime()) / 1000)

const addSecondsToDate = (date, seconds) =>
    new Date(date.getTime() + seconds * 1000)

export default {
    methods: {
        updateTimer: function() {
            var now = new Date()

            if (now > this.expiry) {
                this.expiresInText = ' Expired'
                this.onExpire()
                return
            }

            this.expiresInText = formatTime(
                secondsBetweenDates(now, this.expiry),
            )
            setTimeout(this.updateTimer, 1000)
        },
    },
    created: function() {
        this.expiry = addSecondsToDate(new Date(), this.expiresInSeconds)
        this.updateTimer()
    },
    data: function() {
        return {
            expiry: null,
            expiresInText: null,
        }
    },
    props: {
        expiresInSeconds: {
            type: Number,
            required: true,
        },
        onExpire: {
            type: Function,
            required: true,
        },
    },
}
</script>