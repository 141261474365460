<template>
  <div v-if="showDoorRecentSalesModal">
    <div class="modal-backdrop fade in"></div>
    <div class="modal">
      <div class="door-controls-modal-dialog">
        <div class="modal-content door-controls-modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">Recent Sales</h4>
            <button class="link-button" @click.prevent="toggleDoorRecentSalesModal">
              <span>Close</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="modal-item">
              <DoorSalesRecentSales :recentOrders="recentOrders" :ordersBaseUrl="ordersBaseUrl" :venueTimezone="venueTimezone" />
            </div>
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoorSalesRecentSales from './door-sales-recent-sales.vue'

export default {
  props: {
    showDoorRecentSalesModal: {
      type: Boolean,
      required: true,
    },
    toggleDoorRecentSalesModal: {
      type: Function,
      required: true,
    },
    eventId: {
      type: String,
      required: true,
    },
    recentOrders: {
      type: Array,
      required: false,
    },
    ordersBaseUrl: {
      type: String,
      required: true,
    },
    venueTimezone: {
      type: String,
      required: true,
    }
  },
  data: function () { return {} },
  watch: {
    showDoorRecentSalesModal: function (showDoorRecentSalesModal) {
      if (showDoorRecentSalesModal) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },
  },
  computed: {},
  methods: {},
  mounted() {},
  components: { DoorSalesRecentSales },
};
</script>

<style lang="scss" scoped>
@import "../../../../../wwwroot/scss/_variables";

.modal-header {
  background-color: white;
}

.door-controls-modal-dialog {
  position: relative;
  width: 100%;
}

.door-controls-modal-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: none;
  border-radius: 0;
  box-shadow: none;
  min-height: 100vh;
  text-align: left;
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.modal-item {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
}

@media #{$medium-screen} {
  .door-controls-modal-dialog {
    margin: 70px auto;
    max-width: 46em;
  }

  .door-controls-modal-content {
    min-height: auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  }
}
</style>
