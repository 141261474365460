<template>
  <div class="flex flex-col door-seller-profile">
    <div class="flex align-items-center justify-between">
      <h4>Available Stripe Terminals</h4>
      <button class="link-button refresh-button" @click="initialize">
        <i class="icon icon-rotate"></i>
      </button>
    </div>

    <div v-if="!error" class="flex flex-col">
      <loading-spinner v-if="busy" />

      <div v-if="!busy">
        <div v-if="!selectedTerminalId" class="warning-container">
          Please select a Stripe terminal to use.
        </div>

        <div class="terminals-list">
          <button
            v-for="reader in discoveredReaders"
            :key="reader.id"
            class="terminal"
            :class="[isSelectedReader(reader) && 'selected', reader.status]"
            @click="selectReader(reader)"
          >
            <i class="icon icon-mobile-screen"></i>

            <div class="flex flex-col flex-1">
              <div class="flex items-center terminal-label">
                {{ reader.label }}
                <i class="icon icon-circle online-status"></i>
              </div>
              <span class="terminal-description"
                >Last seen {{ formatReaderLastSeen(reader) }} ago</span
              >
            </div>

            <div class="flex justify-center reset-container">
              <button
                v-if="isSelectedReader(reader) && !selectedReaderBusy"
                class="link-button flex flex-col items-center reset-button"
                @click.stop="resetReader(reader)"
              >
                <i class="icon icon-eject"></i>
                <span>Reset</span>
              </button>
              <loading-spinner
                v-if="isSelectedReader(reader) && selectedReaderBusy"
                loadingText=" "
              />
            </div>
          </button>
        </div>
      </div>
    </div>

    <div v-if="error" role="alert" class="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { StripeTerminalError } from "../../../errors";
import { EventBus } from "../../../common";
import { STRIPE_TERMINAL_STATE_CHANGED } from "../../../events";
import formatDistance from "date-fns/formatDistance";
import parseISO from "date-fns/parseISO";

export default {
  props: {
    discoverReadersUrl: {
      type: String,
      required: true,
    },
    cancelReaderActionUrl: {
      type: String,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
    selectedTerminalId: {
      type: String,
      required: false,
    },
    upsertDoorSalesPreferencesUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      busy: null,
      error: null,
      terminal: null,
      discoveredReaders: null,
      selectedReaderBusy: null,
    };
  },

  methods: {
    initialize() {
      Promise.resolve()
        .then(this.clearError)
        .then(this.setBusy)
        .then(this.discoverReaders)
        .catch(this.handleError)
        .then(this.hideBusy);
    },

    setBusy() {
      this.busy = true;
    },

    hideBusy() {
      this.busy = false;
    },

    handleError(error) {
      if (error instanceof StripeTerminalError) {
        this.error = error.message;
      } else {
        console.error(error.message);
        this.error =
          "Oops, something went wrong. Refresh the page to try again.";
      }
    },

    clearError() {
      this.error = null;
    },

    discoverReaders() {
      return Promise.resolve()
        .then(() => axios.get(this.discoverReadersUrl))
        .then(({ data: { readers } }) => {
          if (readers.length === 0) {
            throw new StripeTerminalError(
              "No Stripe terminals available at the current location."
            );
          } else {
            this.discoveredReaders = readers;
            this.selectedReader = this.discoveredReaders.find(
              (reader) => reader.id === this.selectedTerminalId
            );
          }
        });
    },

    isSelectedReader(reader) {
      return this.selectedTerminalId === reader.id;
    },

    formatReaderLastSeen(reader) {
      return formatDistance(parseISO(reader.lastSeenAt), new Date());
    },

    selectReader(reader) {
      this.emitReaderEvent("selected", reader.id);
      return axios
        .post(this.upsertDoorSalesPreferencesUrl, {
          stripeTerminalId: reader.id,
        })
        .catch(this.handleError);
    },

    setSelectedReaderBusy() {
      this.selectedReaderBusy = true;
    },

    hideSelectedReaderBusy() {
      this.selectedReaderBusy = false;
    },

    emitReaderEvent(state, terminalId) {
      const payload = {
        state,
        terminalId,
      };

      this.$emit(STRIPE_TERMINAL_STATE_CHANGED, payload);
      EventBus.$emit(STRIPE_TERMINAL_STATE_CHANGED, payload);
    },

    resetReader(reader) {
      Promise.resolve()
        .then(this.setSelectedReaderBusy)
        .then(() => axios.post(this.cancelReaderActionUrl, { stripeReaderId: reader.id }))
        .then(({ data }) => {
          if (!data.success) {
            throw new StripeTerminalError(data.errorMessage);
          }
        })
        .then(() => this.emitReaderEvent("reset", reader.id))
        .catch(this.handleError)
        .then(this.hideSelectedReaderBusy);
    },
  },

  mounted() {
    this.initialize();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../wwwroot/scss/variables";
.refresh-button {
  .icon {
    background-color: $primary-color;
  }
}

.terminals-list {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0.75rem;
}

.terminal {
  background: none;
  text-align: left;
  appearance: none;

  display: flex;
  align-items: center;
  gap: 1rem;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.5rem;
  border-color: $ibox-color;
  padding: 1rem 1rem;

  .icon-mobile-screen {
    background-color: $ibox-color;
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
  }

  .terminal-description {
    font-size: 0.8rem;
    font-weight: 400;
  }

  .terminal-label {
    font-weight: 400;
  }

  .online-status {
    background-color: $danger-color;
    margin-left: 0.375rem;
    height: 10px;
    width: 10px;
  }

  .reset-container {
    width: 40px;
  }

  .reset-button {
    color: $danger-color;
    font-size: 0.925rem;
    padding: 0;
    position: relative;
    top: 2px;

    .icon {
      background-color: $danger-color;
      height: 16px;
      width: 16px;
      margin-left: 6px;
    }
  }

  &.selected {
    border-color: transparent;
    box-shadow: 0 0 0 3px $primary-color;

    .icon-mobile-screen {
      background-color: $primary-color;
    }
  }

  &.online {
    .online-status {
      background-color: $success-color;
    }
  }
}

.warning-container {
  background: $danger-color;
  text-align: center;
  color: white;
  font-weight: 400;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.error {
  color: $error-color;
  line-height: 1.5rem;
  font-weight: 400;
}

@media #{$medium-screen} {
  .terminals-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
</style>
