// see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error - ES5 Custom Error Object
export function PaymentGatewayGetTokenError(message, fileName, lineNumber) {
    var instance = new Error(message, fileName, lineNumber)
    Object.setPrototypeOf(instance, Object.getPrototypeOf(this))
    if (Error.captureStackTrace) {
        Error.captureStackTrace(instance, PaymentGatewayGetTokenError)
    }
    return instance
}

PaymentGatewayGetTokenError.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        writable: true,
        configurable: true,
    },
})

if (Object.setPrototypeOf) {
    Object.setPrototypeOf(PaymentGatewayGetTokenError, Error)
} else {
    PaymentGatewayGetTokenError.__proto__ = Error
}

export function AxiosRedirectIntercepted(message, fileName, lineNumber) {
    var instance = new Error(message, fileName, lineNumber)
    Object.setPrototypeOf(instance, Object.getPrototypeOf(this))
    if (Error.captureStackTrace) {
        Error.captureStackTrace(instance, AxiosRedirectIntercepted)
    }
    return instance
}

AxiosRedirectIntercepted.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        writable: true,
        configurable: true,
    },
})

if (Object.setPrototypeOf) {
    Object.setPrototypeOf(AxiosRedirectIntercepted, Error)
} else {
    AxiosRedirectIntercepted.__proto__ = Error
}

export function StripeTerminalError(message, fileName, lineNumber) {
    var instance = new Error(message, fileName, lineNumber)
    Object.setPrototypeOf(instance, Object.getPrototypeOf(this))
    if (Error.captureStackTrace) {
        Error.captureStackTrace(instance, StripeTerminalError)
    }
    return instance
}

StripeTerminalError.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        writable: true,
        configurable: true,
    },
})

if (Object.setPrototypeOf) {
    Object.setPrototypeOf(StripeTerminalError, Error)
} else {
    StripeTerminalError.__proto__ = Error
}
