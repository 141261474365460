<template>
  <div class="flex flex-col door-seller-profile">
    <h4>Seller Profile</h4>
    <div class="flex justify-between">
      <div class="flex flex-col">
        <span>{{ user.name }}</span>
        <span>{{ user.email }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../../wwwroot/scss/variables";

.door-seller-profile {
  text-align: left;

  .icon-arrow-right-from-bracket {
    background-color: $primary-color;
  }
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
