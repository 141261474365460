<template>
  <div class="recent-sales">
    <div v-if="recentOrders && recentOrders.length" class="recent-orders">
      <div v-for="order in recentOrders"
           :key="order.id"
           class="order-card">
        <div class="order-details">
          <div v-html="parseOrderDescription(order.description)" class="order-description">
          </div>
        </div>
        <div class="order-action">
          <div class="order-metadata">
            <div class="order-date">{{ formatOrderDate(order.orderDateInVenueTime) }}</div>
          </div>
          <a :href="generateOrderLink(order.id)" target="_blank" class="pure-button-primary">View Order</a>
        </div>
      </div>
    </div>
    <div v-if="!recentOrders || !recentOrders.length" class="no-sales">No recent sales</div>
  </div>
</template>

<script>

export default {
  props: {
    recentOrders: {
      type: Array,
      required: false,
    },
    ordersBaseUrl: {
      type: String,
      required: true,
    },
    venueTimezone: {
      type: String,
      required: true,
    }
  },
  methods: {
    formatOrderDate(localDateTimeString) {
      return new Date(localDateTimeString).toLocaleDateString("en-au", { weekday:"long", year:"numeric", month:"short", day:"numeric", hour: "numeric", minute: "numeric"});
    },
    generateOrderLink(orderId) {
      return `${this.ordersBaseUrl}orders/${orderId}`
    },
    parseOrderDescription(description) {
      return description.replace(/\r\n/g, "<br />");
    },

  },
  name: "door-sales-recent-sales"
}
</script>

<style lang="scss" scoped>
.recent-sales {
  margin-top: -20px;
}

.recent-orders {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.order-card {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.order-details {
  align-self: flex-start;
}

.order-action {
  align-self: flex-end;
  text-align: right;
}

.order-description {
  color: #333;
  font-weight: 500;
  font-size: 14px;
}

.order-date {
  font-size: 14px;
}

.order-action a {
  display: inline-block;
  background-color: #0078e7;
  color: #fff;
  padding: 5px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  width: 100px;
  font-size: 15px;
  text-decoration: none;
  text-align: center;
  border-radius: 6px;
}

.order-action a:hover {
  background-color: #005cbf;
}

.no-sales {
  padding-left: 5px;
}

</style>