<template>
  <div class="flex items-center loading-spinner" style="gap: 0.5rem">
    <span>{{ loadingText || "Loading" }}</span>
    <i class="icon icon-circle-notch"></i>
  </div>
</template>
<script>
export default {
  props: {
    loadingText: {
      type: String,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../wwwroot/scss/variables";

.loading-spinner {
  line-height: 1.5rem;

  .icon {
    background-color: $primary-color;
    margin-top: 1px;
    height: 16px;
    width: 16px;
    animation: fa-spin 2s infinite linear;
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
</style>
