<template>
  <div>
    <div class="is-flex nav-buttons">
      <div class="nav-item" v-if="cancelCartUrl">
        <button type="button"
                class="door-control-button control-icon"
                @click="toggleCancelCartDialog">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
          </svg>
        </button>
      </div>

      <div class="nav-item">
        <button class="door-control-button control-icon" type="button" @click="toggleDoorRecentSalesModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
          </svg>
        </button>
      </div>

      <div class="nav-item">
        <button class="door-control-button control-icon" type="button" @click="toggleDoorControlModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
          </svg>

        </button>
      </div>

      <div class="nav-item">
      <button class="door-control-button control-icon avatar"
              type="button"
              @click="toggleDoorControlModal">
        <div class="avatar-circle">
          <span class="avatar-circle-inner">{{ user.initials }}</span>
        </div>
      </button>
        </div>
    </div>

    <modal
        v-if="showCancelCartDialog"
        v-cloak
        :is-confirmation="true"
        :modal-type="'confirmation'"
        :on-close-modal="toggleCancelCartDialog"
        v-on:confirm="cancelCart">
      <template #body>
        <div class="modal-body-content">
          <div class="modal-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
            </svg>
          </div>
          <div class="modal-text">
            <h3>Cancel Cart</h3>
            <p>Are you sure you want to cancel the cart?</p>
          </div>
        </div>
      </template>
    </modal>

    <DoorRecentSalesModal
        :showDoorRecentSalesModal="showDoorRecentSalesModal"
        :toggleDoorRecentSalesModal="toggleDoorRecentSalesModal"
        :eventId="eventId"
        :user="user"
        :recentOrders="recentOrders"
        :ordersBaseUrl="ordersBaseUrl"
        :venueTimezone="venueTimezone"
    />
    
    <DoorSalesModal
        :showDoorControlsModal="showDoorControlsModal"
        :toggleDoorControlModal="toggleDoorControlModal"
        :user="user"
        :stripeTerminalConfiguration="stripeTerminalConfiguration"
        :upsertDoorSalesPreferencesUrl="upsertDoorSalesPreferencesUrl"
        :cancelCartUrl="cancelCartUrl"
        :autoScanPref="autoScanPref"
        :updateAutoScanCallback="updateAutoScanCallback"
        :canEditPref="canEditPref"
        :signOutUrl="signOutUrl"
    />
  </div>

</template>



<script>
import cloneDeep from "lodash.clonedeep";
import DoorSalesModal from "./Components/door-sales-modal.vue";
import DoorRecentSalesModal from "./Components/door-sales-recent-sales-modal.vue";
import axios from "axios";

export default {
  props: {
    updateAutoScanCallback: {
      type: Function,
      required: false,
    },
    autoScanPref: {
      type: Boolean,
      required: false,
    },
    canEditPref: {
      type: Boolean,
      required: true,
    },
    ordersBaseUrl: {
      type: String,
      required: true,
    }
  },
  data: function () {
    const preLoadeddata = cloneDeep(window.preLoadeddata);
    return Object.assign(preLoadeddata, {
      showDoorControlsModal: false,
      hasError: true,
      hasConnection: false,
      showDoorRecentSalesModal: false,
      showCancelCartDialog: false,
    });
  },
  methods: {
    toggleDoorControlModal() {
      this.showDoorControlsModal = !this.showDoorControlsModal;
    },
    toggleDoorRecentSalesModal() {
      this.showDoorRecentSalesModal = !this.showDoorRecentSalesModal;
    },
    toggleCancelCartDialog() {
      this.showCancelCartDialog = !this.showCancelCartDialog;
    },
    cancelCart() {
      axios
          .post(this.cancelCartUrl)
          .then(this.clearError)
          .catch(this.handleError);
    },
    clearError() {
      this.error = null;
    },
    handleError(error) {
      this.error = error.response.data.message;
    },
  },
  mounted() {
  },
  components: {DoorSalesModal, DoorRecentSalesModal},
};
</script>

<style lang="scss" scoped>
@import "../../../../wwwroot/scss/variables";

.link {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  margin: 0;
  padding: 5px 10px;
  border-radius: 0.5rem;

  &.disconnected {
    animation: error-flash 1s step-start infinite;
  }

  &.connecting {
    background-color: $warning-color;
  }

  &.connected {
    background-color: $success-color;
  }
}

.is-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-buttons {
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 28px;
    height: 28px;
    margin-right: 13px;

    &:last-child {
      margin-right: 2px;
    }
  }

  .control-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .nav-button-label {
    font-size: 0.8rem;
  }
  
  .door-control-button {
    transition: background-color 0.3s ease;

    svg {
      fill: white;

      &:hover {
        fill: #e1e1e1;
      }
    }
  }
}

.door-control-button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-radius: 50%;
  font-weight: 700;
  display: block;
  margin-right: 0;
  position: relative;
  outline: 0;
  width: 32px;
  height: 32px;
}

.control-button-icon {
  display: flex;
  width: 32px;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: white;
}

.avatar {
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-radius: 50%;
  font-weight: 700;
  display: block;
  margin-right: 0;
  position: relative;
  outline: 0;
  margin-bottom: 2px;

  .avatar-circle {
    background-color: #0055cc;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar-circle-inner {
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 1px;
  }
}

.theme-localtix .avatar .avatar-circle {
  background-color: #ff831be6;
}

.icon-circle {
  border-radius: 50%;
  height: 32px;
  width: 32px;

  img {
    width: 100%;
    height: 100%;
  }
}

@keyframes error-flash {
  50% {
    background-color: $danger-color;
  }
}
</style>
