<template>
  <div class="flex flex-col">
    <h4>Preferences</h4>
    <div class="flex auto-scan-selection">
      <input type="checkbox" id="auto-scan-pref-switch" v-model="autoScanEnabledPref" :disabled="!canEditPref" />
      <label for="auto-scan-pref-switch" class="toggle-label"></label>
      <label class="description-label">Auto scan enabled by default</label>
    </div>
    <button v-on:click="savePreferences" :disabled="canSave()" class="pure-button pure-button-primary button-small"> Save </button>
    <span v-if="!canSave()" class="preference-info"> Note: Any preference changes will only be applied to new orders.</span>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "door-sales-preferences",
  data:function() {
    return {
      autoScanEnabledPref: false,
      initialValues: {
      },
      isDirty: false,
    }
  },
  props: {
    autoScanInitalPref: {
      type: Boolean,
      required: false,
    },
    upsertDoorSalesPreferencesUrl: {
      type: String,
      required: true,
    },
    updateAutoScanCallback: {
      type: Function,
      required: false,
    },
    canEditPref: {
      type: Boolean,
      required: true,
    }
  },
  watch: {
    autoScanEnabledPref: {
      handler() {
        if(this.initialValues.autoScanEnabledPref !== this.autoScanEnabledPref) {
          this.isDirty = true;
        }
        else {
          this.isDirty = false;
        }
      },
      deep: true,
    }
  },
  methods: {
    canSave() {
      return !this.isDirty;
    },
    savePreferences() {
      this.initialValues.autoScanEnabledPref = this.autoScanEnabledPref;
      this.isDirty = false;
      
      return axios
          .post(this.upsertDoorSalesPreferencesUrl, {
            autoScanEnabled: this.autoScanEnabledPref,
          })
          .then(() => {
            if(this.updateAutoScanCallback) {
              this.updateAutoScanCallback(this.autoScanEnabledPref);
            }
            
          })
          .catch(this.handleError);
    }
  },
  mounted() {
    this.initialValues = {
      autoScanEnabledPref: this.autoScanInitalPref,
    }
    this.autoScanEnabledPref = this.initialValues.autoScanEnabledPref;
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../wwwroot/scss/variables";

  .preference-info {
    font-size: 13px;
    font-weight: bold;
    margin-top: 10px;
  }

  button.pure-button-primary, a.pure-button-primary
  {
    max-width: 200px; 
  }

  .btn-primary[disabled] {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: .65;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
</style>