<template>
  <div v-if="showDoorControlsModal">
    <div class="modal-backdrop fade in"></div>
    <div class="modal">
      <div class="door-controls-modal-dialog">
        <div class="modal-content door-controls-modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">Door Sale Controls</h4>
            <button class="link-button" @click.prevent="toggleDoorControlModal">
              <span>Close</span>
            </button>
          </div>

          <div class="modal-body">
            <div v-if="error" role="alert" class="error">
              {{ error }}
            </div>
            <div class="modal-item">
              <DoorSellerProfile :user="user" />
            </div>
            <div class="modal-item">
              <DoorSalesPreferences
                :upsertDoorSalesPreferencesUrl="upsertDoorSalesPreferencesUrl"
                :autoScanInitalPref="autoScanPref"
                :updateAutoScanCallback="updateAutoScanCallback"
                :canEditPref="canEditPref"
              />
            </div>

            <div class="modal-item-last">
              <StripeDevices
                v-if="stripeTerminalConfiguration"
                :discoverReadersUrl="stripeTerminalConfiguration.discoverReadersUrl"
                :cancelReaderActionUrl="stripeTerminalConfiguration.cancelReaderActionUrl"
                :locationId="stripeTerminalConfiguration.locationId"
                :selectedTerminalId="selectedTerminalId"
                :upsertDoorSalesPreferencesUrl="upsertDoorSalesPreferencesUrl"
                @stripe-terminal-state-changed="onTerminalStateChanged"
              />
            </div>
          </div>

          <div class="modal-footer">
            <a
              class="flex flex-col items-center sign-out-link"
              :href="signOutUrl"
            >
              <i class="icon icon-arrow-right-from-bracket"></i>
              <span>Sign Out</span>
            </a>
            <a
              v-if="cancelCartUrl"
              class="flex flex-col items-center cancel-cart-link"
              href="#"
              @click="cancelCart"
            >
              <i class="icon icon-trash-can"></i>
              <span>Cancel Cart</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { EventBus } from "../../../common";
import { NEEDS_STRIPE_TERMINAL } from "../../../events";
import DoorSellerProfile from "./door-seller-profile.vue";
import StripeDevices from "./stripe-devices.vue";
import DoorSalesPreferences from "./door-sales-preferences.vue";

export default {
  props: {
    showDoorControlsModal: {
      type: Boolean,
      required: true,
    },
    toggleDoorControlModal: {
      type: Function,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    stripeTerminalConfiguration: {
      type: Object,
      required: false,
    },
    upsertDoorSalesPreferencesUrl: {
      type: String,
      required: true,
    },
    outletEventSeller: {
      type: Object,
      required: false,
    },
    autoScanPref: {
      type: Boolean,
      required: false,
    },
    updateAutoScanCallback: {
      type: Function,
      required: false,
    },
    canEditPref: {
      type: Boolean,
      required: true,
    },
    cancelCartUrl: {
      type: String,
      required: false,
    },
    signOutUrl: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      selectedTerminalId: null,
      error: null,
    };
  },
  watch: {
    showDoorControlsModal: function (showDoorControlsModal) {
      if (showDoorControlsModal) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },
  },
  methods: {
    onTerminalStateChanged: function (payload) {
      if (payload.state === "selected") {
        this.selectedTerminalId = payload.terminalId;
      }
    },
    cancelCart: function () {
      axios
        .post(this.cancelCartUrl)
        .then(this.clearError)
        .catch(this.handleError);
    },
    clearError() {
      this.error = null;
    },
    handleError(error) {
      this.error = error.response.data.message;
    },
  },
  mounted() {
    if (this.stripeTerminalConfiguration) {
      this.selectedTerminalId =
        this.stripeTerminalConfiguration.selectedTerminalId;

      EventBus.$on(NEEDS_STRIPE_TERMINAL, () => {
        if (!this.showDoorControlsModal) {
          this.toggleDoorControlModal();
        }
      });
    }
  },
  components: { DoorSellerProfile, StripeDevices, DoorSalesPreferences },
};
</script>

<style lang="scss" scoped>
@import "../../../../../wwwroot/scss/_variables";

.door-controls-modal-dialog {
  position: relative;
  width: 100%;
  height: calc(100% - 1.75rem * 2);
}

.door-controls-modal-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: none;
  border-radius: 0;
  box-shadow: none;
  min-height: 100vh;
  min-height: 100dvh;
  text-align: left;
  height: 100%;
}

.modal-header {
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.modal-body {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
}

.modal-item {
  border-bottom: 1px solid $ibox-color;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.modal-item-last {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.error {
  color: $error-color;
  line-height: 1.5rem;
  font-weight: 400;
}

.modal-footer {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  background-color: whitesmoke;

  a {
    text-decoration: none;
    gap: 0.25rem;
  }

  .sign-out-link {
    color: $danger-color;

    .icon {
      background-color: $danger-color;
    }
  }

  .cancel-cart-link {
    color: $primary-color;

    .icon {
      background-color: $primary-color;
    }
  }
}

h4 {
  margin-top: 5px;
}

@media #{$medium-screen} {
  .door-controls-modal-dialog {
    margin: 20px auto;
    max-width: 46em;
    height: calc(100% - 1.75rem * 2);
  }

  .door-controls-modal-content {
    min-height: auto;
    // same as model.scss
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  }
}
</style>
