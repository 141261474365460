<template>
  <div>
    <div class="modal-backdrop fade in" v-on:click.prevent="closeModal"></div>
    <div :class="modalClass" id="myModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">
              <slot name="title"></slot>
            </h4>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <button v-if="!isConfirmation" class="link-button" v-on:click.prevent="closeModal">
              <span>Ok</span>
            </button>
            <div v-if="isConfirmation">
              <button class="pure-button pure-button-secondary modal-button modal-button-cancel" 
                      v-on:click.prevent="closeModal">
                <span>Cancel</span>
              </button>
              <button class="pure-button pure-button-secondary modal-button modal-button-confirm" 
                      v-on:click.prevent="confirmAction">
                <span>Confirm</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isConfirmation: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: 'error',
    },
    onCloseModal: {
      type: Function,
      default: () => window.location.reload()
    }
  },
  mounted() {
    document.body.className += ' ' + 'modal-open'
  },
  methods: {
    closeModal() {
      this.onCloseModal()
    },
    confirmAction() {
      this.$emit('confirm')
    }
  },
  computed: {
    modalClass() {
      return `modal modal-${this.modalType}`;
    },
  },
}
</script>
