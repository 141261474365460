/* 
 Facebook (Meta) Tracker
 URL: https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.11

 Google (gtag) Tracker
 URL: https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce

 Seeka (Converge) Tracker
 URL: https://developers.seeka.co/sdk/js/reference/methods
 Debug mode:
   - Enable debug mode: Converge.debug.setEnabled(true);
   - Controlling Verbosity: Converge.debug.setMinimumLevel("verbose|information|warning|error");
*/

const mergeCustomAnalyticsData = data =>
    Object.assign(window.custom_analytics_data || {}, data)

/*
var subscribe = function () {
  window.EventBus.$on("AddToCart", function (obj) {
    console.log("Add to cart", obj);
  });
  window.EventBus.$on("CheckoutProgress", function (obj) {
    console.log("Checkout 
    progress", obj);
  });
  window.EventBus.$on("InitiateCheckout", function (obj) {
    console.log("Initiate checkout", obj);
  });
  window.EventBus.$on("Purchase", function (obj) {
    console.log("Purchase", obj);
  });
};
window.EventBus && subscribe();
document.body.addEventListener("application-ready", function (e) {
  window.EventBus && subscribe();
});
 */

export const trackWaitlist = emailAddress => {
    try {
        window.oztix_ipa &&
        window.oztix_ipa(
            'event',
            'add_to_waitlist',
            mergeCustomAnalyticsData({
                emailAddress,
            }),
        )
        if (window.Converge) {
            Converge.identity.mergeProfile({
                email: [emailAddress]
            })
            .then(() => {
                Converge.track.lead()
                    .then(() => {
                        // success
                    })
                    .catch((ex) => {
                        // error
                    });
            })
            .catch((ex) => {
                // error
            });
        }

    } catch (e) {
    }
}

export const trackRegistration = emailAddress => {
    try {
        window.oztix_ipa &&
        window.oztix_ipa(
            'event',
            'registration',
            mergeCustomAnalyticsData({
                emailAddress,
            }),
        )
        if (window.Converge) {
            Converge.identity.mergeProfile({
                email: [emailAddress]
            })
            .then(() => {
                Converge.track.lead()
                    .then(() => {
                        // success
                    })
                    .catch((ex) => {
                        // error
                    });
            })
            .catch((ex) => {
                // error
            });
        }

    } catch (e) {
    }
}

export const trackIdle = () => {
    try {
        window.gtag &&
        window.gtag(
            'event',
            'idle',
            mergeCustomAnalyticsData({send_to: 'oztix'}),
        )
    } catch (e) {
    }
}

export const trackAddToCart = ({eventName, inventory, total, eventId, venueName, cartId}) => {
    try {
        window.gtag &&
        window.gtag(
            'event',
            'add_to_cart',
            mergeCustomAnalyticsData({
                oztix_cart_id: cartId,
                items: inventory.map(i => ({
                    id: i.id,
                    name: `${eventName} - ${i.name}`,
                    quantity: i.quantity,
                    price: i.price,
                    category: eventName,
                })),
                value: total,
                send_to: 'oztix'
            }),
        )

        window.fbq_single &&
        window.fbq_single(
            'track',
            'AddToCart',
            mergeCustomAnalyticsData({
                contents: inventory.map(i => ({
                    id: i.id,
                    quantity: i.quantity,
                    item_price: i.price,
                })),
                content_type: 'product',
                value: total,
                currency: 'AUD',
            }),
        )

        window.oztix_ipa &&
        window.oztix_ipa(
            'event',
            'add_to_cart',
            mergeCustomAnalyticsData({
                items: inventory.map(i => ({
                    id: i.id,
                    name: `${eventName} - ${i.name}`,
                    quantity: i.quantity,
                    price: i.price,
                    category: eventName,
                })),
            }),
        )


        window.ttq && window.ttq_single && window.ttq_single('AddToCart')

        window.pintrk && pintrk('track', 'addtocart')

        window.snaptr && snaptr('track', 'ADD_CART')

        window.twq && twq('track', 'AddToCart')

        window.EventBus && window.EventBus.$emit('AddToCart',
            mergeCustomAnalyticsData({
                items: inventory.map(i => ({
                    id: i.id,
                    name: `${eventName} - ${i.name}`,
                    quantity: i.quantity,
                    price: i.price,
                    category: eventName,
                })),
            })
        );
        

        if(window.Converge) {
            const addToCartPromises = inventory.map(i => {
                return Converge.track.addToCart({
                    currencyCode: 'AUD',
                    productIdentifier: eventId,
                    variantIdentifier: i.id,
                    sku: i.id,
                    productName: eventName,
                    variantName: `${eventName} - ${i.name}`,
                    brandName: venueName,
                    unitPrice: i.priceBase,
                    quantity: i.quantity,
                    totalDiscount: undefined
                });
            });

            Promise.all(addToCartPromises)
                .catch(ex => {
                    console.error('Error Converge.track.addToCart:', ex);
                });
        }
    } catch (e) {
    }
}

export const trackCheckoutProgress = step => {
    try {
        window.gtag &&
        window.gtag(
            'event',
            'checkout_progress',
            mergeCustomAnalyticsData({
                checkout_step: step,
                send_to: 'oztix'
            }),
        )

        window.oztix_ipa &&
        window.oztix_ipa(
            'event',
            'checkout_progress',
            mergeCustomAnalyticsData({
                checkout_step: step,
            }),
        )

        window.EventBus && window.EventBus.$emit('CheckoutProgress',
            mergeCustomAnalyticsData({
                checkout_step: step,
                inventory: window.preLoadeddata && window.preLoadeddata.inventory
            })
        )

    } catch (e) {
    }
}

export const trackBeginCheckout = ({eventName, inventory, total, cartId, eventId, venueName}) => {
    try {
        window.gtag &&
        window.gtag(
            'event',
            'begin_checkout',
            mergeCustomAnalyticsData({
                oztix_cart_id: cartId,
                items: inventory.map(i => ({
                    id: i.id,
                    name: `${eventName} - ${i.name}`,
                    quantity: i.quantity,
                    price: i.price,
                    category: eventName
                })),
                send_to: 'oztix'
            }),
        )

        window.fbq_single &&
        window.fbq_single(
            'track',
            'InitiateCheckout',
            mergeCustomAnalyticsData({
                contents: inventory.map(i => ({
                    id: i.id,
                    quantity: i.quantity,
                    item_price: i.price,
                })),
                content_type: 'product',
                value: total,
                currency: 'AUD',
                oztix_cart_id: cartId,
                num_items: inventory
                    .map(i => i.quantity)
                    .reduce((init, current) => init + current, 0),
            }),
        )

        window.oztix_ipa &&
        window.oztix_ipa(
            'event',
            'begin_checkout',
            mergeCustomAnalyticsData({
                oztix_cart_id: cartId,
                items: inventory.map(i => ({
                    id: i.id,
                    name: `${eventName} - ${i.name}`,
                    quantity: i.quantity,
                    price: i.price,
                    category: eventName,
                })),
            }),
        )

        window.EventBus && window.EventBus.$emit('InitiateCheckout',
            mergeCustomAnalyticsData({
                oztix_cart_id: cartId,
                items: inventory.map(i => ({
                    id: i.id,
                    name: `${eventName} - ${i.name}`,
                    quantity: i.quantity,
                    price: i.price,
                    category: eventName,
                })),
            }),
        );

        window.Converge &&
        Converge.track.initiateCheckout({
            products: inventory.map(i => ({
                currencyCode: 'AUD',
                productIdentifier: eventId,
                variantIdentifier: i.id,
                sku: i.id,
                lineItemIdentifier: `${cartId}_${i.id}`,
                productName: eventName,
                variantName: `${eventName} - ${i.name}`,
                brandName: venueName,
                unitPrice: i.priceBase,
                quantity: i.quantity,
                totalDiscount: undefined
            })),
            checkoutIdentifier: cartId,
            cartIdentifier: cartId,
            currencyCode: 'AUD',
        });

    } catch (e) {
    }
}

export const trackShare = (network, url) => {
    try {
        window.gtag &&
        window.gtag(
            'event',
            'share',
            mergeCustomAnalyticsData({
                method: network,
                event_action: 'share',
                content_id: url,
                send_to: 'oztix'
            }),            
        )

        window.oztix_ipa &&
        window.oztix_ipa(
            'event',
            'share',
            mergeCustomAnalyticsData({
                method: network,
                event_action: 'share',
                content_id: url,
            }),
        )
    } catch (e) {
    }
}

export const trackPurchase = ({
                                  eventName,
                                  total,
                                  cartId,
                                  inventory,
                                  refundProtectionHasOptedIn,
                                  eventId,
                                  venueName,
                                  deliveryOptionPrice,
                                  profile,
                                  totalBase
                              }) => {
    try {
        window.gtag &&
        window.gtag(
            'event',
            'purchase',
            mergeCustomAnalyticsData({
                transaction_id: cartId,
                value: total,
                currency: 'AUD',
                items: inventory.map(i => ({
                    id: i.id,
                    name: `${eventName} - ${i.name}`,
                    quantity: i.quantity,
                    price: i.price,
                    category: eventName,
                })),
                send_to: 'oztix'
            }),
        )

        window.gtag && refundProtectionHasOptedIn &&
        window.gtag(
            'event',
            'purchaseHasRefundProtectionOptedIn',
            {send_to: 'oztix'}
        )

        if (window.gtag && window.adwordsConversions.length > 0) {
            for (var i = 0; i < window.adwordsConversions.length; i++) {
                window.gtag('event', 'conversion', {
                    send_to: window.adwordsConversions[i],
                    value: total,
                    currency: 'AUD',
                    transaction_id: cartId,
                })
            }
        }

        window.fbq_single &&
        window.fbq_single(
            'track',
            'Purchase',
            mergeCustomAnalyticsData({
                contents: inventory.map(i => ({
                    id: i.id,
                    quantity: i.quantity,
                    item_price: i.price,
                })),
                content_type: 'product',
                value: total,
                currency: 'AUD',
            }),
        )

        window.oztix_ipa &&
        window.oztix_ipa(
            'event',
            'purchase',
            mergeCustomAnalyticsData({
                transaction_id: cartId,
                value: total,
                currency: 'AUD',
                items: inventory.map(i => ({
                    id: i.id,
                    name: `${eventName} - ${i.name}`,
                    quantity: i.quantity,
                    price: i.price,
                    category: eventName,
                })),
            }),
        )

        window.ttq && window.ttq_single && window.ttq_single('PlaceAnOrder',
            mergeCustomAnalyticsData({
                value: total,
                currency: 'AUD',
                content_id: cartId,
            }))

        window.pintrk && pintrk('track', 'checkout',
            mergeCustomAnalyticsData({
                value: total,
                currency: 'AUD',
                event_id: cartId,
            }))

        window.snaptr && snaptr('track', 'PURCHASE',
            mergeCustomAnalyticsData({
                price: total,
                currency: 'AUD',
                transaction_id: cartId,
            }))

        window.twq && twq('track', 'Purchase',
            mergeCustomAnalyticsData({
                value: total,
                currency: 'AUD',
            }))

        window.EventBus && window.EventBus.$emit('Purchase',
            mergeCustomAnalyticsData({
                transaction_id: cartId,
                value: total,
                currency: 'AUD',
                items: inventory.map(i => ({
                    id: i.id,
                    name: `${eventName} - ${i.name}`,
                    quantity: i.quantity,
                    price: i.price,
                    category: eventName,
                })),
            }),
        );

        if (window.Converge) {
            Converge.identity.mergeProfile({
                firstName: [profile.firstName],
                lastName: [profile.lastName],
                email: [profile.email],
                phone: [profile.mobileNumberE164],
                address: [{
                    locality: profile.address ? profile.address.city || '' : '',
                    postcode: profile.address ? profile.address.postCode || '' : '',
                    stateCode: profile.address ? profile.address.state || '' : '',
                    country: profile.address ? profile.address.country || '' : ''
                }],
                consent: {
                    analytics: {
                        consentGrantedAt: profile.agreeToThirdParty ? new Date().toISOString() : undefined,
                        consentGrantedSourceName: profile.agreeToThirdParty ? 'oztix' : undefined,
                        consentNotGrantedAt: !profile.agreeToThirdParty ? new Date().toISOString() : undefined,
                        consentNotGrantedSourceName: !profile.agreeToThirdParty ? 'oztix' : undefined
                    }
                }
            })
                .then(() => {
                    Converge.track.order({
                        products: inventory.map(i => ({
                            currencyCode: 'AUD',
                            productIdentifier: eventId,
                            variantIdentifier: i.id,
                            sku: i.id,
                            lineItemIdentifier: `${cartId}_${i.id}`,
                            productName: eventName,
                            variantName: `${eventName} - ${i.name}`,
                            brandName: venueName,
                            unitPrice: i.priceBase.toFixed(2),
                            quantity: i.quantity,
                            totalDiscount: undefined
                        })),
                        checkoutIdentifier: cartId,
                        cartIdentifier: cartId,
                        orderIdentifier: cartId,
                        orderNumber: cartId,
                        currencyCode: 'AUD',
                        totalRefunds: undefined,
                        totalDiscounts: undefined,
                        totalTax: undefined,
                        totalShippingPrice: undefined,
                        totalPrice: totalBase.toFixed(2)
                    })
                        .then(() => {
                            // success
                        })
                        .catch((ex) => {
                            // error
                        });
                })
                .catch((ex) => {
                    // error
                });
        }
    } catch (e) {
    }
}

export const trackMerchantWarrior = (ev) => {
    try {
        window.gtag && window.gtag('event', 'merchantwarrior', mergeCustomAnalyticsData(Object.assign({}, ev, { send_to: 'oztix' })))
        window.oztix_ipa && window.oztix_ipa('error', 'merchantwarrior', mergeCustomAnalyticsData(ev))
    } catch (e) {
    }
}

export const trackViewProduct = ({id, name}) => {
    try {
        window.Converge && Converge.track.viewProduct({
            productIdentifier: id,
            productName: name,
            type: "collection"
        });
    } catch (e) {
    }
}
